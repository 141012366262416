import React from 'react'
import styled from '@emotion/styled'

const PostHeaderStyles = styled.header`
  h1,
  p {
    color: ${props => props.theme.post.header};
  }

  p {
    font-weight: 300;
    font-size: 20px;
  }
`

export default function PostHeader ({ title, date }) {
  return (
    <PostHeaderStyles>
      <h1>{title}</h1>
      <p>{date}</p>
    </PostHeaderStyles>
  )
}
