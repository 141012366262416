import React from 'react'
import { graphql } from 'gatsby'
import Article from '../components/layouts/Article'
import PostHeader from '../components/post/PostHeader'
import PostBody from '../components/post/PostBody'
import PostFooter from '../components/post/PostFooter'
import ShareButtons from '../components/social/ShareButtons'
import {Seo} from '../components/Seo'

export default function Post ({ data, location, pageContext }) {
  const { markdownRemark: post } = data
  const {
    site: { siteMetadata: { facebook: { appId: facebookAppId } } }
  } = data
  const { frontmatter, html } = post
  const { title, date, path } = frontmatter
  const { next, prev } = pageContext

  return (
    <Article>
      <PostHeader title={title} date={date} />
      <PostBody html={html} />
      <ShareButtons title={title} path={path} />
      <PostFooter
        prev={prev}
        next={next}
        facebookAppId={facebookAppId}
        path={path}
      />
      <Seo frontmatter={frontmatter} for='post' />
    </Article>
  )
}

export const pageQuery = graphql`
    query BlogPostByPath($path: String!) {
        markdownRemark(
            frontmatter: { path: { eq: $path } }
        ) {
            html
            frontmatter {
                title
                date(formatString: "MMMM DD, YYYY")
                path
                tags
                excerpt
                keywords
            }
        }
        site {
            siteMetadata {
                facebook {
                    appId
                }
            }
        }
    }
`
