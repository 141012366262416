import React from 'react'
import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as appConfig from '../../blog-config'

const ShareButtonsStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  border-top: 1px solid ${props => props.theme.border.primary};
  border-bottom: 1px solid ${props => props.theme.border.primary};
  padding: 20px 0;

  .share-text {
    text-transform: uppercase;
    margin: 0 20px 0 0;
  }

  a {
    margin-right: 20px;
  }

  svg {
    width: 30px !important;
    height: 30px !important;
  }
`

const socialSites = [
  {
    name: 'twitter',
    url: 'https://twitter.com/intent/tweet?url={URLENCODED}&text={TEXT}',
    icon: 'twitter'
  },
  {
    name: 'facebook',
    url: 'https://www.facebook.com/sharer/sharer.php?u={URLENCODED}&t={TEXT}',
    icon: 'facebook-f'
  },
  {
    name: 'google',
    url: 'https://plus.google.com/share?url={URLENCODED}',
    icon: 'google'
  },
  {
    name: 'linkedin',
    url: 'https://www.linkedin.com/shareArticle?url={URLENCODED}',
    icon: 'linkedin-in'
  }
]

export default class ShareButtons extends React.Component {
  constructor (props) {
    super(props)

    this.state = { title: props.title, path: props.path }

    this.openWindow = this.openWindow.bind(this)
  }

  openWindow (e, providerURL) {
    e.preventDefault()

    const width = 600
    const height = 600

    const left =
      window.outerWidth / 2 +
      (window.screenX || window.screenLeft || 0) -
      width / 2
    const top =
      window.outerHeight / 2 +
      (window.screenY || window.screenTop || 0) -
      height / 2

    let config = {
      left: left,
      top: top,
      height: height,
      width: width,
      location: 'no',
      toolbar: 'no',
      status: 'no',
      directories: 'no',
      menubar: 'no',
      scrollbars: 'yes',
      resizable: 'no',
      centerscreen: 'yes',
      chrome: 'yes'
    }

    config = Object.keys(config)
      .map(key => key + '=' + config[key])
      .join(', ')

    return window.open(providerURL, 'window', config)
  }

  render () {
    const { title, path } = this.state

    return (
      <ShareButtonsStyles>
        <p className='share-text'>share</p>

        {socialSites.map(site => {
          const URL = site.url
            .replace('{TEXT}', title)
            .replace(
              '{URLENCODED}',
              appConfig.siteUrl + appConfig.pathPrefix + path
            )

          return (
            <a
              href={URL}
              rel='noopener noreferrer'
              key={URL}
              onClick={e => this.openWindow(e, URL)}
              className='social-button'
            >
              <FontAwesomeIcon
                icon={['fab', site.icon]}
                transform='shrink-6'
                mask={['fas', 'circle']}
              />
            </a>
          )
        })}
      </ShareButtonsStyles>
    )
  }
}
