import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import PostComments from './PostComments'

const PostFooterStyles = styled.footer`
  padding-top: 10px;

  a {
    color: ${props => props.theme.colors.secondary};
  }

  span {
    color: ${props => props.theme.colors.primary};
  }
`

export default function PostFooter ({ prev, next, facebookAppId, path }) {
  return (
    <PostFooterStyles>
      {prev && (
        <p>
          <Link to={prev.frontmatter.path}>
            <span>Previous:</span> {prev.frontmatter.title}
          </Link>
        </p>
      )}

      {next && (
        <p>
          <Link to={next.frontmatter.path}>
            <span>Next:</span> {next.frontmatter.title}
          </Link>
        </p>
      )}
      <PostComments path={path} facebookAppId={facebookAppId} />
    </PostFooterStyles>
  )
}
