import React from 'react'
import { FacebookProvider, Comments } from 'react-facebook'

import * as config from '../../blog-config'

export default function PostComments( props) {
  const { facebookAppId, path } = props

  return (
    <div id='post-comments'>
      <FacebookProvider appId={facebookAppId}>
        <Comments
          href={`${config.siteUrl}${path}`}
          width='100%'
          colorScheme='dark'
        />
      </FacebookProvider>
    </div>
  )
}
