import React from 'react'
import styled from '@emotion/styled'

const PostBodyStyles = styled.main`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${props => props.theme.post.header};
  }

  p {
    color: ${props => props.theme.colors.primary};
  }

  a {
    color: ${props => props.theme.colors.secondary};
  }

  code[class*='language-'] {
    padding: 0.1em 0.3em;
  }

  pre,
  code[class*='language-'] {
    background: ${props => props.theme.pre.background};
  }
`

export default function PostBody ({ html }) {
  return <PostBodyStyles dangerouslySetInnerHTML={{ __html: html }} />
}
