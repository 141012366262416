import React from 'react'
import styled from '@emotion/styled'
import SpringScrollbars from '../SprintScrollbars/SprintScrollbars'
import MainLayout from '../layout'

const ArticleStyles = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 700px;
  margin: 0 auto;

  a:not(.social-button) {
    color: ${props => props.theme.colors.secondary};

    &:hover {
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 999px) {
    padding: 10px;
  }
`

export default function Article (props) {
  return (
    <MainLayout>
      <SpringScrollbars style={{ height: '100vh' }} {...props}>
        <ArticleStyles>{props.children}</ArticleStyles>
      </SpringScrollbars>
    </MainLayout>
  )
}
